body {
  --ion-color-primary: #107c76;
}

.modal-wrapper {
  color: white;
}

.App {
  text-align: center;
  height: 100vh;
}

.app-title-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
}

.app-title {
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}

.app-logo-container {
  position: relative;
  width: 20vmin;
  height: 20vmin;
  display: inline-block;
  z-index: 1;
}

.app-logo-fg {
  position: absolute;
  z-index: 2;
  height: 8vmin;
  pointer-events: none;
  left: 6vmin;
  top: 6vmin;
}

.app-logo-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  animation: App-logo-spin infinite 20s linear;
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  height: 20vh;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
