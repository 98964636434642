.introSlider {
  margin-bottom: 20px;
  background-color: #222222;
  border-radius: 16px;
  color: white;
}

.slideDescription {
  width: 100%;
  margin-bottom: 40px;
}

.introSlide img {
  padding: 40px;
}
